<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="-1"
    :loading="loading"
    :loading-text="'Chargement en cours...'"
    hide-default-footer
    fixed-header
    height="800px"
    item-key="_id"
    sort-by="partType"
    multi-sort
  />
</template>

<script>

export default {
  name: 'MacroKpiPartNumberList',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => (null),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headers () {
      return this.value.headers
    },
    items () {
      return this.value.data
    },
  },
}
</script>
