<template>
  <ag-modal-container
    :title="title"
    left
    width="900px"
    no-content-padding
    @close="back"
  >
    <template #left>
      <v-btn
        color="success"
        fab
        x-small
        :loading="loadingExport"
        @click="exportExcel"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
    </template>
    <macro-kpi-part-number-list
      :value="tableData"
      :loading="loadingData"
      light
    />
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import AgModalContainer from 'src/components/common/AgModalContainer'
import { createExcelFile } from 'src/services/drawExcelService'
import MacroKpiPartNumberList from '../components/MacroKpiPartNumberList'

export default {
  name: 'MacroKpiDetailsContainer',
  components: {
    AgModalContainer,
    MacroKpiPartNumberList,
  },
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
    macroKpiData: {
      type: Array,
      default: () => ([]),
    },
    dateRangetype: {
      type: String,
      default: '',
    },
    dataType: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      loadingExport: false,
    }
  },
  computed: {
    title () {
      if (this.dateRangetype === 'day' && this.dataType === 'finish') return 'Production par jour'
      if (this.dateRangetype === 'day' && this.dataType === 'rejected') return 'Rebuts par jour'
      if (this.dateRangetype === 'day' && this.dataType === 'retouched') return 'Retouches par jour'
      if (this.dateRangetype === 'week' && this.dataType === 'finish') return 'Production par semaine'
      if (this.dateRangetype === 'week' && this.dataType === 'rejected') return 'Rebuts par semaine'
      if (this.dateRangetype === 'week' && this.dataType === 'retouched') return 'Retouches par semaine'
      if (this.dateRangetype === 'month' && this.dataType === 'finish') return 'Production par mois'
      if (this.dateRangetype === 'month' && this.dataType === 'rejected') return 'Rebuts par mois'
      if (this.dateRangetype === 'month' && this.dataType === 'retouched') return 'Retouches par mois'
      if (this.dateRangetype === 'year' && this.dataType === 'finish') return 'Production par année'
      if (this.dateRangetype === 'year' && this.dataType === 'rejected') return 'Rebuts par année'
      if (this.dateRangetype === 'year' && this.dataType === 'retouched') return 'Retouches par année'
      return ''
    },
    tableData () {
      if (!this.macroKpiData || !this.macroKpiData.length) return { headers: [], data: [] }
      const dateRangeData = this.macroKpiData.find(data => data.type === this.dateRangetype)
      const tempData = []
      for (const row of dateRangeData.current[`${this.dataType}PartNumber`]) {
        const alreadyExist = tempData.findIndex(item => item.name === row.name)
        if (alreadyExist > -1) {
          tempData[alreadyExist].current = row.count
        }
        else {
          tempData.push({
            name: row.name,
            partType: row.partType,
            current: row.count,
          })
        }
      }
      for (const row of dateRangeData.last[`${this.dataType}PartNumber`]) {
        const alreadyExist = tempData.findIndex(item => item.name === row.name)
        if (alreadyExist > -1) {
          tempData[alreadyExist].last = row.count
        }
        else {
          tempData.push({
            name: row.name,
            partType: row.partType,
            last: row.count,
          })
        }
      }
      for (const row of dateRangeData.lastTwo[`${this.dataType}PartNumber`]) {
        const alreadyExist = tempData.findIndex(item => item.name === row.name)
        if (alreadyExist > -1) {
          tempData[alreadyExist].lastTwo = row.count
        }
        else {
          tempData.push({
            name: row.name,
            partType: row.partType,
            lastTwo: row.count,
          })
        }
      }
      let subtract = 0
      // eslint-disable-next-line new-cap
      const tempDateCurrent = this.getFormatDate(dateRangeData.type, new this.moment().subtract(subtract, dateRangeData.type).startOf(dateRangeData.type).add(6, 'h'))
      subtract = 1
      // eslint-disable-next-line new-cap
      const tempDateLast = this.getFormatDate(dateRangeData.type, new this.moment().subtract(subtract, dateRangeData.type).startOf(dateRangeData.type).add(6, 'h'))
      subtract = 2
      // eslint-disable-next-line new-cap
      const tempDateLastTwo = this.getFormatDate(dateRangeData.type, new this.moment().subtract(subtract, dateRangeData.type).startOf(dateRangeData.type).add(6, 'h'))

      const headers = []

      headers.push({ align: 'center', text: 'Type de référence', value: 'partType' })
      headers.push({ align: 'center', text: 'Référence', value: 'name' })
      headers.push({ align: 'center', text: tempDateLastTwo, value: 'lastTwo' })
      headers.push({ align: 'center', text: tempDateLast, value: 'last' })
      headers.push({ align: 'center', text: tempDateCurrent, value: 'current' })
      return { data: tempData, headers }
    },
  },
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$router.back()
    },
    async exportExcel () {
      try {
        this.loadingExport = true

        const { headers, data } = this.tableData

        const exportConfig = {
          columns: [],
          view: [{ state: 'frozen', ySplit: 1 }],
          sheetName: 'données',
        }
        for (const header of headers) {
          exportConfig.columns.push({
            header: header.text,
            key: header.value,
          })
        }
        await createExcelFile([{ dataToExport: data, config: exportConfig }], this.title)
      }
      catch (error) {
        this.showErrorNotification(error)
      }
      finally {
        this.loadingExport = false
      }
    },
    getFormatDate (type, date) {
      switch (type) {
        case 'day':
          return `${date.format('LL')}`
        case 'week':
          return `S${date.format('w')}`
        case 'month':
          return `${date.format('MMMM')}`
        case 'year':
          return `${date.format('Y')}`
        default:
          return ''
      }
    },
  },
}
</script>
